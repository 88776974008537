import {  useState } from 'react';
import { ModalCloseBtn } from '../../../assets/svg/svgIcons.jsx';
import {
  layearDefault,
  layearSatellite,
  TrafficImg,
} from '../../../assets/index.js';
import Toggle from '../../toggle/index.jsx';
import { useMapControl,useToggle } from '../../../context/index.js';

import { logEvent } from '../../../analytics.js';
import { useTranslation } from "react-i18next";
const MapLayer = () => {
  const { setMapLayerToggle } = useToggle();
  const { setMapType, mapType, traffic, setTraffic } = useMapControl();
  const [type, setType] = useState(mapType);
  const [isTraffic, setIsTraffic] = useState(traffic);
  const [t,i18n] = useTranslation();


  return (
    <div className='madal-cover modal-small'>
      <div className='modal-dialog modal-dialog-centered'>
        <div className='modal-content'>
          <div className='modal-header'>
            <div className='modal-title'>{t('mapLayers')}</div>
            <button
              className='btn-close'
              onClick={() => setMapLayerToggle(false)}
            >
              <ModalCloseBtn />
            </button>
          </div>
          <div className='modal-body'>
            <div className='layers-list'>
              {[
                {
                  value: t('default'),
                  image: layearDefault,
                  id:"default"
                },
            
                {
                  value: t('satellite'),
                  image: layearSatellite,
                  id:"satellite"
                },
              ].map((item) => (
                <div
                  className={`layer-bx-otr ${
                    type === item.id.toLocaleLowerCase() && `active`
                  }`}
                >
                  <div className='layer-bx'>
                    <img
                      src={item.image}
                      alt='layear-default'
                      onClick={() => setType(item.id.toLocaleLowerCase())}
                    />item
                  </div>
                  <p>{item.value}</p>
                </div>
              ))}
            </div>

            <div className='root-info'>
              <div className='lft-info'>
                <img src={TrafficImg} alt='Traffic' />
              </div>
              <div className='right-content'>
                <div className='inr-content'>
                  <strong>{t('traffic')}</strong>
                  <p>{t('provideIntegratedGeospatialInformation')}</p>
                </div>
                <div className='switch-otr'
                style={{
                  marginRight: i18n.language ==="ar" &&'40px'
                }}
                
                >
                  <Toggle checked={isTraffic} setChecked={setIsTraffic} />
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button
                className='primary-btn save-btn'
                onClick={() => {
                  setMapType(type.toLocaleLowerCase());
                  setMapLayerToggle(false);
                  setTraffic(isTraffic);
                  logEvent("Map Layer Change", "Map Layer Click", `Language: ${i18n.language}`);
                }}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapLayer;
