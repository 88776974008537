import { useState, useEffect } from "react";
import {
  GetSmartSearch,
  GetSmartSearchResult,
  SearchFromGoogle,
} from "../helper/api/requests.ts";
import { useAuth } from "../context/auth.js";
import { useMapControl } from "../context/MapControllContext";
import { useTranslation } from "react-i18next";
import { logEvent } from "../analytics";

const useSmartSearchFetching = (setDisplaySuggestions, setSearch, search) => {
  const [loading, setLoading] = useState(true);
  const [controller, setController] = useState(new AbortController());

  const [displayRecentSearch, setDisplayRecentSearch] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const [t, i18n] = useTranslation();
  const { token, getToken, setToken } = useAuth();
  const { currentLocationDirection } = useMapControl();

  const handleSmartSearch = async (value, FromDirection, needEntrance) => {
    setSuggestions([]);
    logEvent(
      "Smart Search",
      "Search Click",
      `Searching for: ${value}`,
      i18n.language
    );
    try {
      if (controller) {
        controller.abort(); // Abort previous request
      }
      const newController = new AbortController();
      setController(newController); // Set new controller

      if (!FromDirection) {
        setSearch(value);
      }

      const performSearch = async (searchToken) => {
        let response = await GetSmartSearch(
          value,
          searchToken,
          `${currentLocationDirection.lat},${currentLocationDirection.lng}`,
          i18n.language
        );

        if (response.IsExp) {
          const newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          response = await GetSmartSearch(
            value,
            newToken,
            `${currentLocationDirection.lat},${currentLocationDirection.lng}`,
            i18n.language
          );
        }
        return response;
      };

      if (value?.length > 3) {
        setLoading(true);
        let response = await performSearch(token);
        if (response.data?.length === 0) {
          response = await SearchFromGoogle(value, i18n.language);
          let suggestions = response.candidates.map((item) => {
            return {
              text: item.name,
              category: item.types[0],
              location: item.geometry.location,
              fromGoogleApi: true,
            };
          });
          setLoading(false);
          setSuggestions(suggestions);
          logEvent(
            "Smart Search",
            "Google Search Results",
            `Results for: ${value}`,
            i18n.language
          );
        } else {
          setSuggestions(response.data);
          setLoading(false);
          logEvent(
            "Smart Search",
            "Local Search Results",
            `Results for: ${value}`,
            i18n.language
          );
        }
      }
    } catch (error) {
      setLoading(false);
      console.error("Error :", error);
    }
  };

  const handleInputFocus = () => {
    if (search?.length > 3) {
      setDisplaySuggestions(true);
    } else {
      setDisplayRecentSearch(true);
      setDisplaySuggestions(false);
    }
  };

  const handleOnBlur = () => {
    setDisplayRecentSearch(false);
  };

  return {
    loading,
    setLoading,
    handleSmartSearch,
    displayRecentSearch,
    suggestions,
    handleInputFocus,
    handleOnBlur,
    setDisplayRecentSearch,
    setSuggestions,
  };
};

export default useSmartSearchFetching;

//15:07
