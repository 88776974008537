const getLocationName = async (lat, lng, language) => {
  try {
    // const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&accept-language=en`);
    // const responseArabic = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&accept-language=ar`);
    // const data = await response.json();
    // const dataAr = await responseArabic.json();

    return {
      englishName: "Your location",
      arabicName: `موقعك الحالي`,
    };
  } catch (error) {
    console.error("Error with reverse geocoding", error);
  }

  // return new Promise((resolve, reject) => {
  //     const geocoder = new window.google.maps.Geocoder();
  //     const latLng = new window.google.maps.LatLng(lat, lng);

  //     const getGeocode = (language) => {
  //         return new Promise((resolve, reject) => {
  //             geocoder.geocode({ location: latLng, language: language }, (results, status) => {
  //                 if (status === 'OK') {
  //                     if (results[0]) {

  //                         let address = results[0].formatted_address;
  //                         address = address.replace(/^\S+\s+/, '');

  //                         resolve(address);
  //                     } else {
  //                         reject(new Error('No results found'));
  //                     }
  //                 } else {
  //                     reject(new Error(`Geocoder failed due to: ${status}`));
  //                 }
  //             });
  //         });
  //     };

  //     Promise.all([getGeocode('en'), getGeocode('ar')])
  //         .then(([englishName, arabicName]) => {

  //             resolve({
  //                 englishName: englishName,
  //                 arabicName: arabicName
  //             });
  //         })
  //         .catch(reject);
  // });
};
export default getLocationName;
