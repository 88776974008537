import React, { useEffect, useRef } from "react";
import { ModalCloseBtn } from "../../assets/svg/svgIcons";
import {
  PlayStore,
  PlayStore1,
  PhoneImageWebp,
  downloadQRcode,
} from "../../assets/index";
import { useAppearance } from "../../context/AppearanceContext";
import { useTranslation } from "react-i18next";

const DownloadApp = ({ setShowPopup: setShowPopupProp }) => {
  const { setShowPopup: setShowPopupContext } = useAppearance();
  const setShowPopup = setShowPopupProp || setShowPopupContext;
  const [t] = useTranslation();
  const popupRef = useRef(null); // Create a ref for the popup

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowPopup]);

  return (
    <div className="download-app-card-cover">
      <div className="download-app-card" ref={popupRef}>
        {" "}
        {/* Attach ref here */}
        <button
          aria-labelledby="close-button"
          className="btn-close"
          onClick={() => setShowPopup(false)}
        >
          <ModalCloseBtn />
        </button>
        <div className="lft-content">
          <div className="top-side">
            <strong>
              {t("download_makani_popup1")} <br /> {t("download_makani_popup2")}
            </strong>
            <p>{/* Placeholder for additional text */}</p>
          </div>
          <div className="download-group">
            <div className="app-stors">
              <div className="apps">
                <a href="">
                  <img
                    src={PlayStore}
                    alt="playStore"
                    width="102"
                    height="34"
                  />
                </a>
                <a href="">
                  <img
                    src={PlayStore1}
                    alt="Appstore"
                    width="102"
                    height="34"
                  />
                </a>
              </div>
              <div className="qr-code">
                <img src={downloadQRcode} alt="apps" width="71" height="71" />
              </div>
            </div>
          </div>
        </div>
        <div className="right-vector">
          <div className="app-img">
            <img
              src={PhoneImageWebp}
              alt="Phone Image"
              width="128"
              height="231"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
