import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, Outlet } from "react-router-dom";

import { useAuth } from "./auth";

const MapControlContext = createContext();
export const useMapControl = () => useContext(MapControlContext);

export const MapControlProvider = ({ children }) => {
  const location = useLocation();
  const [t, i18n] = useTranslation();
  const loadSetting = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);

    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  };

  const saveSetting = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const [avoidToll, setAvoidTols] = useState(loadSetting("avoidToll", false));
  const [mapType, setMapType] = useState(loadSetting("mapType", "default"));
  const [zoomInBtn, setZoomInBtn] = useState(loadSetting("zoomInBtn", true));
  const [scaleBar, setScaleBar] = useState(loadSetting("scaleBar", true));
  const [routeType, setRouteType] = useState(
    loadSetting("routeType", "fastest")
  );
  const [currentAvoidToll, setCurrentAvoidToll] = useState(avoidToll);

  const [traffic, setTraffic] = useState(loadSetting("traffic", false));
  const [measurementUnit, setMeasurementUnit] = useState(
    loadSetting("measurementUnit", "kilometers")
  );
  const [durationOnCar, setDurationOnCar] = useState("");
  const [directionLoading, setDirectionLoading] = useState(false);

  const [durationOnFeet, setDurationOnFeet] = useState("");
  const [distanceOnCar, setDistanceOnCar] = useState("");
  const [distanceFeet, setDistanceOnFeet] = useState("");
  const [destination, setDestination] = useState({
    position: {
      lat: "",
      lng: "",
    },
    value: "",
  });
  const [origin, setOrigin] = useState({
    position: {
      lat: "",
      lng: "",
    },
    value: "",
  });
  const [directions, setDirections] = useState(null);
  const [startDirection, setStartDirection] = useState(false);

  const [currentLocation, setCurrentLocation] = useState({});
  let [polygonCoords, setPolygonCoords] = useState([]);
  const [makaniCoords, setMakaniCoords] = useState([]);
  const [buildingsPolygon, setBuildingsPolygon] = useState([]);
  const [polygonCoords2, setPolygonCoords2] = useState([]);
  const [center, setCenter] = useState({
    lat: 25.2642691390032,
    lng: 55.3119033649999,
  });

  const [nearestMakani, setNeareastMakani] = useState({});
  const [streetLevel, setStreetLevel] = useState({ lat: "", lng: "" });
  const [zoom, setZoom] = useState(15);
  const [directionMode, setDirectionMode] = useState("DRIVING");
  const [waypoints, setWayPoints] = useState([]);

  const [tapPosition, setTapPosition] = useState({});
  const [currentLocationDirection, setCurrentLocationDirection] = useState({});

  const [drawDirection, setDrawDirection] = useState(false);
  const [selectedDirectionMap, setSelectedDirectionMap] = useState(false);
  const { token } = useAuth();
  const [selectedRoute, setSelectedRoute] = useState(routeType);
  const [homeSelectedMakani, setHomeSelectedMakani] = useState(-1);
  const [homeFavroiteSelect, setHomeFavroiteSelect] = useState(-1);
  const mapRef = useRef(null);
  const [disaplyNearestMakani, setDispalyNearestMakani] = useState(false);
  const [settingsAlert, setSettingAlert] = useState({
    message: null,
    value: false,
  });
  const [prevSettings, setPrevSettings] = useState({
    avoidToll: loadSetting("avoidToll", false),
    zoomInBtn: loadSetting("zoomInBtn", true),
    scaleBar: loadSetting("scaleBar", true),
    traffic: loadSetting("traffic", false),
  });
  const [onPressMarker, setOnPressMarker] = useState({
    lat: "",
    lng: "",
  });
  const [shareFromMakaniCard, setShareFromMakaniCard] = useState({
    value: "",
    isShare: false,
  });

  const [myMakaniCardRef, setMyMakaniCardRef] = useState({
    makaniId: null,
    value: false,
  });

  const updateSettingAlert = (key, newValue, label) => {
    if (prevSettings[key] !== newValue) {
      const message = newValue ? t(`${label}Enabled`) : t(`${label}Disabled`);
      setSettingAlert({ message: message, value: true });
      setTimeout(() => {
        setSettingAlert({ message: null, value: false });
      }, 2000);

      setPrevSettings((prev) => ({ ...prev, [key]: newValue }));
    }
  };

  useEffect(() => {
    saveSetting("mapType", mapType);
  }, [mapType]);

  useEffect(() => {
    saveSetting("avoidToll", avoidToll);
    if (location.pathname === "/settings") {
      updateSettingAlert("avoidToll", avoidToll, "avoidToll");
    }
  }, [avoidToll, t, location.pathname]);

  useEffect(() => {
    saveSetting("zoomInBtn", zoomInBtn);
    if (location.pathname === "/settings") {
      updateSettingAlert("zoomInBtn", zoomInBtn, "zoomInBtn");
    }
  }, [zoomInBtn, t, location.pathname]);

  useEffect(() => {
    saveSetting("scaleBar", scaleBar);
    if (location.pathname === "/settings") {
      updateSettingAlert("scaleBar", scaleBar, "scaleBar");
    }
  }, [scaleBar, t, location.pathname]);

  useEffect(() => {
    saveSetting("traffic", traffic);
    if (location.pathname === "/settings") {
      updateSettingAlert("traffic", traffic, "traffic");
    }
  }, [traffic, t, location.pathname]);

  useEffect(() => {
    saveSetting("measurementUnit", measurementUnit);
  }, [measurementUnit, t, location.pathname]);

  const [longPressPosition, setLongPressPosition] = useState({
    lat: "",
    lng: "",
    CommunityE: "",
    CommunityA: "",
  });
  const lightTheme = [];

  const darkTheme = [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#8ec3b9",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1a3646",
        },
      ],
    },
    {
      featureType: "administrative.country",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#64779e",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#4b6878",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#334e87",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "geometry",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#283d6a",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#6f9ba5",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#3C7680",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#304a7d",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#98a5be",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#2c6675",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#255763",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#b0d5ce",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#023e58",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#98a5be",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#1d2c4d",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#283d6a",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#3a4762",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#0e1626",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#4e6d70",
        },
      ],
    },
  ];

  const [locations, setLocations] = useState([
    {
      value: "",
      position: {
        lat: currentLocationDirection.lat,
        lng: currentLocationDirection.lng,
      },
    },
  ]);

  const updateDirection = (locations) => {
    if (locations?.length > 1) {
      setOrigin({
        position: {
          lat: locations[0]?.position?.lat,
          lng: locations[0]?.position?.lng,
        },
        value: locations[0].value,
      });

      setDestination({
        value: locations[locations?.length - 1].value,
        position: {
          lat: locations[locations?.length - 1].position.lat,
          lng: locations[locations?.length - 1].position.lng,
        },
      });

      setWayPoints(
        [...locations].splice(1, locations?.length - 2).map((item) => {
          return {
            location:
              item.position.lat && item.position.lng
                ? { lng: item.position.lng, lat: item.position.lat }
                : item.value,
            stopover: true,
          };
        })
      );
      setStartDirection(true);
    } else {
      if (locations[0].value === "your Location") {
        setOrigin({
          position: {
            lat: 25.3048,
            lng: 55.3708,
          },
          value: "your Location",
        });
      } else {
        setOrigin({
          position: {
            lat: "",
            lng: "",
          },
          value: locations[0],
        });
      }
      setDurationOnFeet("");
      setDistanceOnCar("");
      setDistanceOnFeet("");
      setDurationOnCar("");
      setDirections(null);
      setStartDirection(false);
      setDestination({
        value: "",
        position: {
          lat: "",
          lng: "",
        },
      });
      setWayPoints("");
      setDirectionLoading(false);
    }
  };

  return (
    <MapControlContext.Provider
      value={{
        setAvoidTols,
        avoidToll,
        setMapType,
        mapType,
        setZoomInBtn,
        zoomInBtn,
        setScaleBar,
        scaleBar,
        setRouteType,
        routeType,
        traffic,
        setTraffic,
        measurementUnit,
        setMeasurementUnit,
        durationOnCar,
        durationOnFeet,
        setDurationOnCar,
        setDurationOnFeet,
        distanceOnCar,
        distanceFeet,
        setDistanceOnCar,
        setDistanceOnFeet,
        destination,
        setDestination,
        origin,
        setOrigin,
        directions,
        setCurrentLocation,
        setDirections,
        currentLocation,
        polygonCoords,
        setPolygonCoords,
        makaniCoords,
        setMakaniCoords,
        polygonCoords2,
        setPolygonCoords2,
        center,
        setCenter,
        streetLevel,
        setStreetLevel,

        setZoom,
        zoom,
        setDirectionMode,
        directionMode,
        waypoints,
        setWayPoints,
        lightTheme,
        darkTheme,
        locations,
        setLocations,
        setDrawDirection,
        drawDirection,
        startDirection,
        setStartDirection,
        nearestMakani,
        setNeareastMakani,
        buildingsPolygon,
        setPolygonCoords,
        setBuildingsPolygon,
        setSelectedDirectionMap,
        selectedDirectionMap,
        updateDirection,
        directionLoading,
        setDirectionLoading,
        setTapPosition,
        tapPosition,
        currentAvoidToll,
        setCurrentAvoidToll,
        setSelectedRoute,
        selectedRoute,
        setHomeSelectedMakani,
        homeSelectedMakani,
        homeFavroiteSelect,
        setHomeFavroiteSelect,
        setCurrentLocationDirection,
        currentLocationDirection,
        mapRef,
        setLongPressPosition,
        longPressPosition,
        selectedRoute,
        setDispalyNearestMakani,
        disaplyNearestMakani,
        settingsAlert,
        setSettingAlert,
        onPressMarker,
        setOnPressMarker,
        setShareFromMakaniCard,
        shareFromMakaniCard,
        myMakaniCardRef,
        setMyMakaniCardRef,
      }}
    >
      {children}
    </MapControlContext.Provider>
  );
};
export default MapControlProvider;
//10:08://setDirectionMode
//16:14
