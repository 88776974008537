import { useTranslation } from "react-i18next";
import {
  profileSetting,
  avatarIcon,
  UAEPASS_Logo,
  point,
  signClose,
  settingArrow,
  signupArrowDark,
  profileSettingDark,
} from "../../assets/index";
import { useNavigate, useLocation } from "react-router-dom";
import { PageHeaderTitle } from "../../component/index";
import React, { useEffect, useState } from "react";
import "../../scss/common.scss";
import "../../scss/sign-in.scss";
import { useAppearance } from "../../context/AppearanceContext";
import { UseRemoveMapShape } from "../../customHooks";
import AccessibleButton from "../../component/Accessibility/accessibility";
import { useAuth } from "../../context/auth.js";
import { useDraggableContext } from "../../context/DraggableContext.js";
import { MOBILE_VIEW_DRAGGER_HEIGHT } from "../../helper/appConstants.js";
import { logEvent } from "../../analytics.js";

const SignUp = () => {
  const [t] = useTranslation();
  const { theme } = useAppearance();
  const [isMobileView, setIsMobileView] = useState(false);
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const { distanceDragged } = useDraggableContext();
  const navigate = useNavigate();
  const { sessionId, userId, setUrl, authUrl } = useAuth();
  const location = useLocation();
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  UseRemoveMapShape();

  const handleLogin = async () => {
    logEvent("Button Click", "Sign Up Button Clicked");
    window.location.href = authUrl;
    setUrl(window.location.origin);
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() => {
    const calculatedHeight = 160 - distanceDragged - MOBILE_VIEW_DRAGGER_HEIGHT;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1100);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  if (sessionId && userId) {
    return navigate("/profile");
  }

  return (
    <div
      className="sideBarContent inner-spacing-01"
      style={{
        padding: isMobileView ? " " : "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      {!isMobileView && (
        <PageHeaderTitle
          title={t("profile")}
          fromMenu={location?.state?.fromMenu}
        />
      )}

      <div className="profileInfo swipe-line">
        {isMobileView && (
          <div className="signWarper" onClick={() => navigate("/")}>
            <div className="close_btn">
              <img src={signClose} alt="close" />
            </div>
          </div>
        )}
        <div className="profileInfoDetails">
          <div className="avater">
            <img src={avatarIcon} alt="avater" width="29" height="38" />
          </div>
          <div>
            <p>{t("hello")}</p>
          </div>
        </div>
      </div>
      <div
        className="signupWrapper"
        style={{
          overflowY: isMobileScreen ? "auto" : "",
          height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
        }}
      >
        <p className="description">{t("accessFeature")}</p>
        <div
          // className='signupWarper'
          className="signupWarperHome"
          style={{
            border: theme === "dark" ? "4px solid #4E5972" : "",
          }}
        >
          {/* <img src={point} alt='point' width='29' height='29' /> */}
          <img src={UAEPASS_Logo} alt="point" width="29" height="29" />
          <AccessibleButton
            ariaLabel={t("Click here to sign up using UAE Pass")}
            onClick={handleLogin}
          >
            <p className="signUpTitle" onClick={() => handleLogin()}>
              {t("signUp")}
            </p>
          </AccessibleButton>
        </div>
        <AccessibleButton ariaLabel={t("Click here for settings")}>
          <div className="settingWarper" onClick={() => navigate("/settings")}>
            <div className="profileWrapperLeft">
              <div className="profileWrapper">
                <img
                  src={theme === "dark" ? profileSettingDark : profileSetting}
                  alt="profileSetting"
                  width="19"
                  height="19"
                />
              </div>
              <p>{t("settings")}</p>
            </div>
            <div>
              <img
                width="10"
                height="14"
                src={theme === "dark" ? signupArrowDark : settingArrow}
                alt="settingArrow"
              />
            </div>
          </div>
        </AccessibleButton>
      </div>
      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};
export default SignUp;
//12:05

// 09b67a44-11fe-47ae-88ea-a62df5af00c7

// f8e8e093-0d25-4617-be5f-d9b2eeb87573
////17:56
