import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import {
  SortDropDown,
  EditFavoritesRoutes,
  DeleteMakaniCard,
} from "../../component/index";
import {
  useAppearance,
  useFavorites,
  useMapControl,
} from "../../context/index.js";
import { formatTimestamp, isLocationEnabled } from "../../helper/index.js";
import "./style.css";
import {
  flagSmall,
  flagBig,
  favoriteSettingDark,
  routesSettings,
  flagBigDark,
  flagSmallDark,
  favoritePlaceArrow,
} from "../../assets/index";
import { parseDistanceWithUnit } from "../../helper/utils/parseDistance.js";

const FavoritesRoutes = () => {
  const navigate = useNavigate();
  const [t, il8n] = useTranslation();
  const [editIndexs, setEditIndexs] = useState([]);
  const [sortToggle, setSortToggle] = useState(false);
  const [editRoute, setEditRoute] = useState({
    isEditLocationToggle: false,
    index: -1,
  });
  const [isLocation, setIsLocation] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState("");
  const [deleteRouteToggle, setDeleteRouteToggle] = useState({
    item: {},
    isDeleteRoute: false,
    index: -1,
  });
  const {
    setFavoritesMarker,
    setSelectedFavoriteMarker,
    setFavoritePalceInfo,
    setRefetch,
  } = useFavorites();

  const { measurementUnit } = useMapControl();
  const { theme } = useAppearance();
  useEffect(() => {
    setSelectedFavoriteMarker(-1);
    setFavoritePalceInfo({});
    setFavoritesMarker([]);
    setRefetch((prev) => !prev);
  }, []);

  const toggleEditMode = (index) => {
    if (editIndexs.includes(index)) {
      setEditIndexs(editIndexs.filter((item) => item !== index));
    } else {
      setEditIndexs([...editIndexs, index]);
    }
  };

  const { locations, routeLocations, fetchSyncFavorites, setRouteLocations } =
    useFavorites();
  let arr = [t("dateAdded"), t("distance"), t("alphabetically")];

  useEffect(() => {
    const checkLocation = async () => {
      try {
        const locationEnabled = await isLocationEnabled();
        setIsLocation(locationEnabled);
      } catch (error) {
        console.error("Error checking location:", error);
        setIsLocation(false);
      }
    };

    const sortedLocations = routeLocations.sort(
      (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
    );

    setRouteLocations([...sortedLocations]);
    checkLocation();
  }, []);

  //
  // useEffect(() => {
  //   const sortedLocations = routeLocations.sort(
  //     (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
  //   );
  //   setRouteLocations([...sortedLocations])
  // }, [deleteRouteToggle.isDeleteRoute])
  //

  function convertToMinutes(timeString) {
    let parts = timeString.split(" ");
    let totalMinutes = 0;

    for (let i = 0; i < parts?.length; i += 2) {
      let value = parseInt(parts[i], 10);
      let unit = parts[i + 1];

      if (unit.startsWith("hr")) {
        totalMinutes += value * 60;
      } else if (unit.startsWith("min")) {
        totalMinutes += value;
      }
    }
    return totalMinutes;
  }

  const handleSort = (value) => {
    let sortedLocations = [...routeLocations];
    if (value === "date") {
      const sortedLocations = routeLocations.sort(
        (a, b) => b.values[0].LastUpdated - a.values[0].LastUpdated
      );
      setRouteLocations([...sortedLocations]);
    } else if (value === "alphabetically") {
      setRouteLocations(
        routeLocations.sort((a, b) => a.name.localeCompare(b.name))
      );
    } else if (value === "distance") {
      setRouteLocations(
        routeLocations.sort(
          (a, b) => b.values[0].FavValue - a.values[0].FavValue
        )
      );
    }
    setRouteLocations(sortedLocations);
    setSortToggle(false);
  };
  return (
    <>
      <div className="sortWarper">
        <p>{t("sortby")}</p>
        <p className="selectedValue" onClick={() => setSortToggle(!sortToggle)}>
          {arr[selectedIndex]}
        </p>
        <SortDropDown
          setSortToggle={setSortToggle}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          sortToggle={sortToggle}
          handleSort={handleSort}
        />
      </div>

      <div className="routesFavorites">
        {routeLocations.map((item, index) =>
          editIndexs.includes(index) ? (
            deleteRouteToggle.index === index &&
            deleteRouteToggle.isDeleteRoute ? (
              <div className="deleteFavoriteLocation">
                <DeleteMakaniCard
                  title={t("Delete_Route")}
                  description={t("Delete_Route_Description")}
                  setDeleteMakaniToggle={setDeleteRouteToggle}
                  item={{
                    FavType: deleteRouteToggle.item.FavType,
                    FavName: deleteRouteToggle.item.FavName,
                  }}
                  setEditIndexs={setEditIndexs}
                  FromRoute={true}
                />
              </div>
            ) : (
              <div
                className={
                  il8n.language === "ar"
                    ? "editableFavoritePlacesAr"
                    : "editableFavoritePlaces"
                }
              >
                <div className="placeInfo">
                  <div className="place_pWraper">
                    <p
                      className="place_p"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.name}
                    </p>
                  </div>
                  <div>
                    <p className="distance">
                      <span>
                        {!isLocation
                          ? "--"
                          : parseDistanceWithUnit(
                              measurementUnit,
                              Math.round(
                                (item.values[0].FavValue / 1000) * 10
                              ) / 10
                            )}{" "}
                      </span>
                      {measurementUnit == "kilometers" ? t("km") : t("miles")}
                    </p>
                  </div>

                  <div
                    className="arrowWarper"
                    onClick={() => {
                      toggleEditMode(index);
                    }}
                  >
                    <img src={favoritePlaceArrow} alt="arrow" />
                  </div>
                </div>
                <div className="favoriteAction">
                  <div
                    className="deleteFavorite"
                    onClick={() => {
                      setDeleteRouteToggle({
                        item: {
                          FavType: item.values[0].FavType,
                          FavName: item.name,
                        },
                        index,
                        isDeleteRoute: true,
                      });
                    }}
                  >
                    <p>{t("delete")}</p>
                  </div>
                  <div
                    className={
                      il8n.language === "ar"
                        ? "sharedFavoriteAr"
                        : "sharedFavorite"
                    }
                    onClick={() => {
                      setValue(item.name);
                      setEditIndexs(
                        editIndexs.filter((item) => item !== index)
                      );
                      setEditRoute({
                        isEditLocationToggle: true,
                        index: index,
                      });
                    }}
                  >
                    <p>{t("edit")}</p>
                  </div>

                  {/* <div  >
                  <p >{t('share')}</p>
                </div> */}
                </div>
              </div>
            )
          ) : editRoute.isEditLocationToggle && editRoute.index === index ? (
            <EditFavoritesRoutes
              item={item}
              setEditRoute={setEditRoute}
              value={value}
              setValue={setValue}
              isLocation={isLocation}
            />
          ) : (
            <div key={index} className="routeFavorite">
              <div className="routesLogoWarper">
                <img
                  width="11"
                  height="17"
                  src={theme === "dark" ? flagBigDark : flagBig}
                  alt="routes logo"
                  className="flag"
                />
                <img
                  width="9"
                  height="13"
                  src={theme === "dark" ? flagSmallDark : flagSmall}
                  alt="routes logo"
                  className="flag2"
                />
              </div>
              <div className="routesLine">
                <p className="routeName">{item.name}</p>
                <div
                  className="lineWarper"
                  onClick={() => {
                    let locations = item.values.map((route) => {
                      return {
                        value:
                          il8n.language === "ar"
                            ? route.BuildingNameA
                            : route.BuildingNameE,
                        BldgNameEn: route.BuildingNameE,
                        BuildingNameA: route.BuildingNameA,
                        position: {
                          lat: parseFloat(route.Location.split(",")[0]),
                          lng: parseFloat(route.Location.split(",")[1]),
                        },
                        favName: item.name,
                        favType: route.FavType,
                      };
                    });
                    navigate("/direction", {
                      state: {
                        locations: locations,
                      },
                    });
                  }}
                >
                  <p>
                    {il8n.language === "ar"
                      ? item.values[0].BuildingNameA
                      : item.values[0].BuildingNameE}
                  </p>
                  <div className="line">
                    {item.values.map((item, index) => {
                      return (
                        <div
                          className={`marker ${
                            index === 0 ? "marker1" : "marker2"
                          }`}
                        >
                          <div className="markerChild"></div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="routeDetails">
                <p className="distance">
                  <span>
                    {!isLocation
                      ? "--"
                      : parseDistanceWithUnit(
                          measurementUnit,
                          Math.round((item.values[0].FavValue / 1000) * 10) / 10
                        )}{" "}
                  </span>
                  {measurementUnit == "kilometers" ? t("km") : t("miles")}
                </p>
                <p
                  className="street"
                  data-tooltip-id="my-tooltip2"
                  data-tooltip-content={
                    il8n.language === "ar"
                      ? item?.values[item.values?.length - 1].BuildingNameA
                      : item?.values[item.values?.length - 1].BuildingNameE
                  }
                >
                  {il8n.language === "ar"
                    ? item?.values[item.values?.length - 1].BuildingNameA
                    : item?.values[item.values?.length - 1].BuildingNameE}
                </p>
                <Tooltip id="my-tooltip2" className="custom-tooltip" />
                <p className="street">
                  {formatTimestamp(item.values[0].LastUpdated)}
                </p>
              </div>
              <div
                className="routeSettingWarper"
                onClick={() => {
                  toggleEditMode(index);
                }}
              >
                <img
                  src={theme === "dark" ? favoriteSettingDark : routesSettings}
                  alt="settings"
                />
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default FavoritesRoutes;
