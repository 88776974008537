import { useState, useEffect } from "react";
import {
  GetSmartSearchResult,
  GetBuildingOutLineEntrancePoints as GetBuildingOutLineEntrancePointsAPI,
  GetZoningRegulation,
  GetFavorites,
  ShareLocation,
  GetFloors,
  GetInitialParams,
} from "../helper/api/requests.ts";
import { useTranslation } from "react-i18next";
import { useMapControl } from "../context/MapControllContext.js";
import { useAuth } from "../context/auth.js";
import { toast } from "react-toastify";
import {
  calculateDistanceBetweenCoordinates,
  updateRecentSearch,
  processShapes,
} from "../helper/index.js";
import { useNavigate } from "react-router-dom";

const useSmartSearchResultFetching = (
  featureclassId,
  dgis,
  isParcel,
  m,
  position,
  searchValue,
  suggestion
) => {
  const { measurementUnit } = useMapControl();
  const [makani, setMakani] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [poi, setPoi] = useState([]);
  const [parcelInfo, setParcelInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [coummity, setCoummity] = useState([]);
  const [color, setColor] = useState("");
  const [indoorenabled, setIndoorenabled] = useState(false);
  const [googleResult, setGoogleResult] = useState([]);
  const [geoLocationError, setGeoLocationError] = useState(false);
  const [makaniCardLimit, setMakaniCardLimit] = useState(3);
  const [allowAddMakani, setAllowAddMakani] = useState(true);

  const navigate = useNavigate();
  const [t, il8n] = useTranslation();
  const {
    setMakaniCoords,
    setCenter,
    setZoom,
    setBuildingsPolygon,
    currentLocation,
    setTapPosition,
    currentLocationDirection,
    mapRef,
    buildingsPolygon,
  } = useMapControl();
  const { token, sessionId, userId, getToken, setToken, initialToken } =
    useAuth();

  useEffect(() => {
    if (suggestion && suggestion.fromGoogleApi) {
      setLoading(false);
      setMakaniCoords([
        {
          lat: suggestion.location.lat,
          lng: suggestion.location.lng,
        },
      ]);
      setCenter(suggestion.location);
      setGoogleResult([
        {
          building: suggestion.text,
          location: suggestion.location,
        },
      ]);
    }
  }, [suggestion]);

  const calculateBoundingBox = (polygon) => {
    let minLat = Infinity,
      maxLat = -Infinity;
    let minLng = Infinity,
      maxLng = -Infinity;

    polygon.forEach((point) => {
      if (point?.lat < minLat) minLat = point?.lat;
      if (point?.lat > maxLat) maxLat = point?.lat;
      if (point?.lng < minLng) minLng = point?.lng;
      if (point?.lng > maxLng) maxLng = point?.lng;
    });

    return { minLat, maxLat, minLng, maxLng };
  };

  /*const calculateZoomLevel = (width, height) => {
    // Constants for zoom calculation (these may need fine-tuning)
    const ZOOM_MAX = 18.9;
    const ZOOM_MIN = 10;
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_LN2 = Math.log2;
  
    const latFraction = (height / 180);
    const lngFraction = (width / 360);
  
    const latZoom = ZOOM_LN2(WORLD_DIM.height / latFraction);
    const lngZoom = ZOOM_LN2(WORLD_DIM.width / lngFraction);
    return Math.min(Math.max(Math.min(latZoom, lngZoom), ZOOM_MIN), ZOOM_MAX);
  };*/

  const calculateZoomLevel = (width, height) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 18.9;
    const mapWidth = window.innerWidth;
    const mapHeight = window.innerHeight;

    const latFraction = height / 180;
    const lngFraction = width / 360;

    const latZoom = Math.log2(mapHeight / WORLD_DIM.height / latFraction);
    const lngZoom = Math.log2(mapWidth / WORLD_DIM.width / lngFraction);

    const zoomLevel = Math.min(latZoom, lngZoom);
    return zoomLevel;
    //return Math.min(zoomLevel, ZOOM_MAX);
  };

  function getZoomLevelAndCenter(polygon, mapcenter) {
    if (polygon?.length > 0) {
      const { minLat, maxLat, minLng, maxLng } = calculateBoundingBox(polygon);
      const width = maxLng - minLng;
      const height = maxLat - minLat;

      const zoomLevel = calculateZoomLevel(width, height);
      const center = { lat: (minLat + maxLat) / 2, lng: (minLng + maxLng) / 2 };

      return { zoomLevel, center };
    } else {
      const zoomLevel = 18.6;
      const center = { lat: mapcenter.lat, lng: mapcenter.lng };
      return { zoomLevel, center };
    }
  }

  function getCombinedPoliygons(polygans) {
    const combinedPolygon = {
      polygan: [],
    };

    // Iterate through each object and concatenate the polygan arrays
    polygans.forEach((item) => {
      combinedPolygon.polygan = combinedPolygon.polygan.concat(item.polygan);
    });
    return { combinedPolygon };
  }
  function getBounds(cPolygon) {
    const bounds = new window.google.maps.LatLngBounds();
    cPolygon.forEach((point) => {
      bounds.extend(new window.google.maps.LatLng(point.lat, point.lng));
    });
    return bounds;
  }

  useEffect(() => {
    const controller = new AbortController(); // Create an AbortController
    const { signal } = controller;

    const checkIndoorEnabled = async () => {
      try {
        let initialparams_result = await GetInitialParams(t, token);

        // Handle token expiration
        if (initialparams_result.isExp) {
          const newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          initialparams_result = await GetInitialParams(t, newToken);
        }

        if (initialparams_result.error) {
          toast.error(initialparams_result.error, {
            type: "error",
            isLoading: false,
            autoClose: 3000,
            position: il8n.language === "ar" ? "top-left" : "top-right",
            style: {
              textAlign: il8n.language === "ar" ? "right" : "left",
            },
          });
          setIndoorenabled(false);
        } else if (initialparams_result.Data.InitialParms) {
          setMakaniCardLimit(
            initialparams_result.Data.InitialParms.Makani_Card_Limit
          );

          setIndoorenabled(
            initialparams_result.Data.InitialParms.indoor_navigation_enabled
          );
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      }
    };

    const fetchData = async (featureclassId, dgis) => {
      setLoading(true);
      const map = mapRef.current;
      try {
        let result = await GetSmartSearchResult(featureclassId, dgis, token, {
          signal,
        });

        // Handle token expiration
        if (result.IsExp) {
          const newToken = await getToken();
          setToken(newToken);
          localStorage.setItem("token", JSON.stringify(newToken));
          result = await GetSmartSearchResult(featureclassId, dgis, newToken, {
            signal,
          });
        }

        if (signal.aborted) return; // Stop execution if aborted

        if (result.makani?.[0]?.Makani) {
          checkIndoorEnabled();
        }

        let newMakaniCoords = result?.makani?.map((item) => {
          const [lng, lat] = item.SHAPE.split(",");
          return { lng: Number(lng), lat: Number(lat), entType: item.EntType };
        });

        if (featureclassId === "5") {
          const parcelPolygons =
            result?.parcel?.map((item) => ({
              polygan: processShapes(item?.SHAPE),
            })) || [];

          const buildingPolygons =
            result?.buildings?.map((item) => ({
              polygan: processShapes(item?.SHAPE),
            })) || [];

          const polygons = [...parcelPolygons, ...buildingPolygons];
          setBuildingsPolygon(polygons);

          const { combinedPolygon } = getCombinedPoliygons(polygons);
          const { zoomLevel, center } = getZoomLevelAndCenter(
            combinedPolygon.polygan,
            newMakaniCoords[0]
          );
          setCenter(center);
          setZoom(zoomLevel);
        } else if (featureclassId === "6") {
          setCoummity(result.community);
          const polygons =
            result.community.map((item) => ({
              polygan: processShapes(item?.SHAPE),
              color: "red",
            })) || [];

          setBuildingsPolygon(polygons);
          const { combinedPolygon } = getCombinedPoliygons(polygons);
          const { zoomLevel, center } = getZoomLevelAndCenter(
            combinedPolygon.polygan,
            newMakaniCoords[0]
          );
          setCenter(center);
          setZoom(zoomLevel);
        } else {
          const polygons =
            result?.buildings?.map((item) => ({
              polygan: processShapes(item?.SHAPE),
            })) || [];

          setBuildingsPolygon(polygons);
          const { combinedPolygon } = getCombinedPoliygons(polygons);
          const { zoomLevel, center } = getZoomLevelAndCenter(
            combinedPolygon.polygan,
            newMakaniCoords?.[0]
          );
          setCenter(center);
          setZoom(zoomLevel);
        }

        // Calculate distance
        let distance = "";
        if (result?.makani[0]?.SHAPE) {
          distance = calculateDistanceBetweenCoordinates(
            {
              lat: Number(currentLocationDirection.lat),
              lng: Number(currentLocationDirection.lng),
            },
            {
              lat: Number(result?.makani[0]?.SHAPE?.split(",")[1]),
              lng: Number(result?.makani[0]?.SHAPE?.split(",")[0]),
            },
            measurementUnit === "kilometers" ? "km" : "mile"
          );
        }

        // Fetch favorites
        let FavoriteResult = [];
        if (userId && sessionId) {
          FavoriteResult = await GetFavorites(userId, sessionId, token);
        }

        const isFavoriteMakaniNumber = FavoriteResult?.filter(
          (FavoriteResultItem) => FavoriteResultItem?.IsMyMakani
        )?.length;

        if (isFavoriteMakaniNumber >= makaniCardLimit) {
          setAllowAddMakani(false);
        }

        const MakaniWithDistance = result?.makani?.map((item) => {
          const distance = calculateDistanceBetweenCoordinates(
            {
              lat: Number(currentLocationDirection.lat),
              lng: Number(currentLocationDirection.lng),
            },
            {
              lat: Number(item?.SHAPE?.split(",")[1]),
              lng: Number(item?.SHAPE?.split(",")[0]),
            },
            measurementUnit === "kilometers" ? "km" : "mile"
          );

          const isFavoriteLocation = FavoriteResult?.find(
            (FavoriteResultItem) =>
              FavoriteResultItem?.FavValue === item?.Makani &&
              FavoriteResultItem?.FavType === "LOCATION"
          );

          const isFavoriteMakani = FavoriteResult?.find(
            (FavoriteResultItem) =>
              FavoriteResultItem?.FavValue === item?.Makani &&
              FavoriteResultItem?.IsMyMakani
          );

          return {
            ...item,
            distance,
            searchText: searchValue,
            isFavoriteMakani: !!isFavoriteMakani,
            isFavoriteLocation: !!isFavoriteLocation,
          };
        });

        if (result?.buildings?.length > 0) {
          updateRecentSearch(
            result,
            distance,
            "isBuilding",
            searchValue,
            featureclassId,
            dgis
          );
        } else if (result?.makani?.length > 0) {
          updateRecentSearch(
            result,
            distance,
            "isMakani",
            null,
            featureclassId,
            dgis
          );
        }

        setLoading(false);
        setMakani(MakaniWithDistance);
        setMakaniCoords(newMakaniCoords);
        setBuildings(result.buildings);
        setPoi(result.poi);
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error(error);
        }
      } finally {
        setLoading(false);
      }
    };

    if (token && currentLocationDirection.lat && currentLocationDirection.lng) {
      if (m) fetchData(2, m);
      else if (featureclassId && dgis) fetchData(featureclassId, dgis);
    }

    // Cleanup: Abort fetch on component unmount or navigation
    return () => {
      controller.abort();
    };
  }, [dgis, featureclassId, currentLocationDirection, token]);

  useEffect(() => {
    const fetchZoningData = async () => {
      if (featureclassId === "5") {
        const map = mapRef.current;
        try {
          let landInfo = await GetZoningRegulation(t, dgis, token);
          setColor(landInfo.Zoningregulations.Regulation.Color);
          setParcelInfo(landInfo);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      }
    };

    if (featureclassId && dgis) fetchZoningData();
  }, [featureclassId, dgis, token]);

  useEffect(() => {
    if (buildingsPolygon?.length > 0 && color) {
      let newBolygan = buildingsPolygon.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            color: color,
          };
        } else {
          return {
            ...item,
          };
        }
      });

      setBuildingsPolygon(newBolygan);
    }
  }, [color]);

  const GetBuildingOutLineEntrancePoints = async (token, { lat, lng }) => {
    const toastId = toast.loading(t("loading"), {
      position: il8n.language === "ar" ? "top-left" : "top-right",
      style: {
        textAlign: il8n.language === "ar" ? "right" : "left",
      },
    });
    try {
      let result = await GetBuildingOutLineEntrancePointsAPI(t, token, {
        lat,
        lng,
      });

      if (result.isExp) {
        let newToken = await getToken();
        setToken(newToken);
        localStorage.setItem("token", JSON.stringify(newToken));
        result = await GetBuildingOutLineEntrancePointsAPI(t, newToken, {
          lat,
          lng,
        });
      }

      if (result.error) {
        toast.update(toastId, {
          render: result.error,
          type: "error",
          isLoading: false,
          autoClose: 3000,
          position: il8n.language === "ar" ? "top-left" : "top-right",
          style: {
            textAlign: il8n.language === "ar" ? "right" : "left",
          },
        });
        setTapPosition({});
      } else {
        toast.dismiss(toastId);
        navigate("/searchResult", {
          state: {
            lng: lng,
            lat: lat,
          },
        });
        setTapPosition({});
      }
    } catch (error) {
      toast.update(toastId, {
        render: error + " by tap on map",
        type: "error",
        isLoading: false,
        autoClose: 3000,
        position: il8n.language === "ar" ? "top-left" : "top-right",
        style: {
          textAlign: il8n.language === "ar" ? "right" : "left",
        },
      });
      setTapPosition({});
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchBuildingOutlineEntrancePoints = async (lat, lng) => {
      let FavoriteResult = [];
      if (userId && sessionId) {
        FavoriteResult = await GetFavorites(userId, sessionId, token);
      }
      const isFavoriteMakaniNumber = FavoriteResult?.filter(
        (FavoriteResultItem) => {
          return FavoriteResultItem?.IsMyMakani;
        }
      )?.length;
      if (isFavoriteMakaniNumber >= makaniCardLimit) {
        setAllowAddMakani(false);
      }
      try {
        setLoading(true);
        setGeoLocationError(false);

        const result = await GetBuildingOutLineEntrancePointsAPI(t, token, {
          lat,
          lng,
        });

        if (result.error === "No data found") {
          setGeoLocationError(true);
        } else {
          const newMakaniCoords = result?.Enterances?.map((item) => {
            const [lat, lng] = item?.LatLng.split(",");
            return {
              lng: Number(lng),
              lat: Number(lat),
              entType: item.EntType,
            };
          });
          let distance = calculateDistanceBetweenCoordinates(
            {
              lat: Number(currentLocationDirection.lat),
              lng: Number(currentLocationDirection.lng),
            },
            {
              lat: Number(result?.Enterances[0]?.LatLng?.split(",")[0]),
              lng: Number(result?.Enterances[0]?.LatLng?.split(",")[1]),
            },
            measurementUnit === "kilometers" ? "km" : "mile"
          );

          setMakaniCoords(newMakaniCoords);
          //setCenter(newMakaniCoords[0]);
          setBuildingsPolygon(
            result?.Buildings?.map((item) => {
              return {
                polygan: processShapes(item?.Coord, false, true),
              };
            })
          );
          //setZoom(17.5);
          const { zoomLevel, center } = getZoomLevelAndCenter(
            newMakaniCoords,
            newMakaniCoords[0]
          );
          setCenter(center);
          setZoom(zoomLevel);

          const makaniResult = result?.Enterances?.map((item) => {
            return {
              BldgNameAr: item?.BldgNameAr.trim(),
              BldgNameEn: item?.BldgNameEn.trim(),
              Makani: item?.Makani,
              EntNameA: item?.EntNameA.trim(),
              EntNameE: item?.EntNameE.trim(),
              EntType: item?.EntType,
              CommEn: item?.CommEn.trim(),
              CommAr: item?.CommAr.trim(),
              SHAPE: item?.LatLng.split(",").reverse().join(", "),
              TelNo: item?.TelNo,
              Url: item?.Url,
              EmirateA: item?.EmirateA.trim(),
              EmirateE: item?.EmirateE,
              RoadNameAr: item?.RoadNameAr.trim(),
              RoadNameEn: item?.RoadNameEn.trim(),
              distance: distance,
            };
          });
          setMakani(makaniResult);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (token && position?.lat && position?.lng) {
      fetchBuildingOutlineEntrancePoints(position?.lat, position?.lng);
    }
  }, [token, position?.lat, position?.lng]);

  return {
    makani,
    buildings,
    poi,
    GetBuildingOutLineEntrancePoints,
    loading,
    parcelInfo,
    coummity,
    indoorenabled,
    googleResult,
    geoLocationError,
    allowAddMakani,
    setIndoorenabled,
  };
};

export default useSmartSearchResultFetching;
