const calculate = (legs) => {
  let totalDistance = 0;
  let totalDurationMins = 0;
  let unit = "";

  for (let i = 0; i < legs?.length; i++) {
    const distanceText = legs[i].distance.text;
    const durationText = legs[i].duration.text;

    // Handle distance
    if (distanceText.includes("km") || distanceText.includes("كم")) {
      unit = distanceText.includes("km") ? "km" : "كم";
      const distanceValue = parseFloat(
        distanceText.replace(" km", "").replace(" كم", "")
      );
      totalDistance += distanceValue;
    } else if (distanceText.includes("mi") || distanceText.includes("ميل")) {
      unit = distanceText.includes("mi") ? "mi" : "ميل";
      const distanceValue = parseFloat(
        distanceText.replace(" mi", "").replace(" ميل", "")
      );
      totalDistance += distanceValue;
    }

    // Handle duration
    const durationParts = durationText.split(" ");
    let durationValue = 0;

    for (let j = 0; j < durationParts?.length; j++) {
      if (
        durationParts[j].includes("hour") ||
        durationParts[j].includes("ساعة")
      ) {
        durationValue += parseInt(durationParts[j - 1]) * 60;
      } else if (
        durationParts[j].includes("mins") ||
        durationParts[j].includes("دقيقة")
      ) {
        durationValue += parseInt(durationParts[j - 1]);
      }
    }

    totalDurationMins += durationValue;
  }

  const distance = totalDistance.toFixed(2) + " " + unit;
  const durationInHours = Math.floor(totalDurationMins / 60);
  const durationInMinutes = totalDurationMins % 60;

  let duration;
  if (durationInHours > 0) {
    duration = `${durationInHours} ${
      unit === "km" || "mi" ? "hrs" : "ساعة"
    } ${durationInMinutes} ${unit === "km" || "mi" ? "mins" : "دقيقة"}`;
  } else {
    duration = `${durationInMinutes} ${
      unit === "km" || "mi" ? "mins" : "دقيقة"
    }`;
  }

  return {
    distance: distance,
    duration: duration,
  };
};

export default calculate;
