import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  useFavorites,
  useAppearance,
  useMapControl,
} from "../../../context/index.js";
import { favoriteFlag, favoritePlaceFlagDark } from "../../../assets/index";
import { parseDistanceWithUnit } from "../../../helper/utils/parseDistance.js";
const EditFavoritePlaceModel = ({
  index,
  item,
  isLocation,
  value,
  setValue,
}) => {
  const [t, il8n] = useTranslation();
  const {
    setSelectedFavoriteMarker,
    setEditLocationToggle,
    fetchSyncFavorites,
  } = useFavorites();
  const { theme } = useAppearance();
  const { measurementUnit } = useMapControl();
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handelEdit = () => {
    {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      fetchSyncFavorites(item, {
        action: "edit",
        value: value,
        lastUpdate: timestamp,
      });
      setEditLocationToggle({
        isEditLocationToggle: false,
        index: -1,
        item: {},
      });
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handelEdit();
    }
  };

  return (
    <div className="editFavoriteLocation">
      <div
        className="EditableFavouritePlace"
        onClick={() => {
          setSelectedFavoriteMarker(index);
        }}
      >
        <div className="favouritePlaceLeft">
          <div className="favouritePlaceLogo">
            <img
              src={theme === "dark" ? favoritePlaceFlagDark : favoriteFlag}
              style={{
                paddingRight: il8n.language === "ar" ? "25%" : 0,
                paddingLeft: il8n.language === "en" ? "37%" : "0",
              }}
              alt="favorite Flag"
            />
          </div>
          <div className="favouritePlaceDetails">
            <p className="favouritePlaceTitle">{item.FavName}</p>
            <p className="favouritePlaceSubTitle">
              {" "}
              {il8n.language === "ar" ? item.BuildingNameA : item.BuildingNameE}
            </p>
          </div>
        </div>
        <div className="favouritePlaceRight">
          <div>
            <p
              className="favouritePlaceMakainNumber"
              style={{
                textAlign: il8n.language === "ar" ? "left" : "right",
              }}
            >
              {item.FavValue}
            </p>
            <p
              className="favouritePlaceMakainDistance"
              style={{
                textAlign: il8n.language === "ar" ? "left" : "right",
              }}
            >
              <span>
                {!isLocation
                  ? "--"
                  : parseDistanceWithUnit(
                      measurementUnit,
                      item?.distance?.toFixed(2)
                    )}{" "}
              </span>{" "}
              {measurementUnit == "kilometers" ? t("km") : t("miles")}
            </p>
          </div>
        </div>
      </div>
      <div className="EditFavoriteLocation">
        <div className="EditFavoriteLocationInputWraper">
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="EditFavoriteBtns">
          <button
            className="CancelFavoriteLocationBtn"
            onClick={() => {
              setEditLocationToggle({
                isEditLocationToggle: false,
                index: -1,
                item: {},
              });
            }}
          >
            {t("cancel")}
          </button>
          <button
            className="EditFavoriteLocationBtn"
            onClick={() => handelEdit()}
          >
            {" "}
            {t("save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFavoritePlaceModel;
