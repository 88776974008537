import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageHeaderTitle, MakaniCard } from "../../component/index";
import {
  UseRemoveMapShape,
  useGetBuildingAddressFetching,
} from "../../customHooks/index";
import "../../scss/common.scss";
import "./style.css";
import Skeleton from "react-loading-skeleton";
import React from "react";

import { useAppearance } from "../../context/AppearanceContext";

const BuildingAddress = () => {
  UseRemoveMapShape();
  const { theme } = useAppearance();
  const [t, il8n] = useTranslation();
  const location = useLocation();
  const { community, street, building } = location.state;
  const [screenWidth, setScreenWidth] = React.useState(window.innerHeight);

  const { buildingInfo, loading } = useGetBuildingAddressFetching(
    community,
    street,
    building
  );

  React.useEffect(() => {
    const updateScreenWidth = () => {
      setScreenWidth(window.innerHeight);
    };

    window.addEventListener("resize", updateScreenWidth);

    window.addEventListener("orientationchange", updateScreenWidth);

    return () => {
      window.removeEventListener("resize", updateScreenWidth);
      window.removeEventListener("orientationchange", updateScreenWidth);
    };
  }, []);

  let hightOfSidebar = screenWidth - 191;

  return (
    <div
      className="sideBarContent swipe-line building-address"
      style={{
        height: hightOfSidebar | (screenWidth - 191),
        overflowY: "auto",
      }}
    >
      <PageHeaderTitle
        title={t("searchByBuildingAddress")}
        customStyle={{
          padding: "",
        }}
        parent="/searchByBuildingAddress"
      />
      {loading ? (
        <>
          <p className="buildingAddressTitle">
            <Skeleton width={100} />
          </p>

          <p class="buildingAddressInfo">
            <Skeleton width={150} />
          </p>

          <div className="DeatilsMakaniCards slick-cards-cover">
            <div className="slider-container">
              <div
                className={`deatilsMakaniCardActive singleMakaniCard deatilsMakaniCard`}
              >
                <div className="MakaniCardHeader">
                  <div>
                    <Skeleton
                      width={100}
                      height={50}
                      className="logo-in-card"
                    />
                  </div>
                  <div className="copyWrapper">
                    <p className="makaniCardNumber">
                      <Skeleton width={100} className="copy__btn" />
                    </p>
                  </div>
                  <div className="d-flex-cstm">
                    <Skeleton width={50} height={50} className="love" />
                  </div>
                </div>
                <div className="MakaniCardContent">
                  <div
                    className="MakaniCardContentText"
                    style={{
                      padding: il8n.language === "ar" && "5px 15px",
                    }}
                  >
                    <Skeleton width={100} />
                    <Skeleton
                      width={40}
                      className="MakaniCardContentDesinence"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="makaniCardWarper without-slide">
          {buildingInfo?.length > 0 && (
            <p className="buildingAddressTitle">
              {t("buildingNo")}
              {buildingInfo?.length > 0 && ` ${buildingInfo[0].AddressE}`}
            </p>
          )}
          {buildingInfo?.length > 0 && (
            <p class="buildingAddressInfo">
              {buildingInfo?.length > 0 &&
                (il8n.language === "ar"
                  ? ` ${buildingInfo[0].CommunityA}`
                  : ` ${buildingInfo[0].CommunityE}`)}
            </p>
          )}

          {!loading && (
            <MakaniCard
              item={{
                Makani: buildingInfo[0].Makani,
                CommAr: buildingInfo[0].CommunityA,
                CommEn: buildingInfo[0].CommunityE,
                SHAPE: buildingInfo[0].LatLng.split(",").reverse().join(","),
                BldgNameEn: buildingInfo[0].BldgNameEn,
                BldgNameAr: buildingInfo[0].BldgNameAr,
                distance: buildingInfo[0].distance,
                isFavoriteLocation: buildingInfo[0].isFavoriteLocation,
                isFavoriteMakani: buildingInfo[0].isFavoriteMakani,
                StreetE: buildingInfo[0].StreetE,
                StreetA: buildingInfo[0].StreetA,
                AddressE: buildingInfo[0].AddressE,
                AddressA: buildingInfo[0].AddressA,
              }}
              index={0}
              theme={theme}
              width="140px"
              gap="10px"
            />
          )}
        </div>
      )}
      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};

export default BuildingAddress;

//17:00
