import { Toggle } from "../../component/index";
import { rightArrow } from "../../assets/index";
import { useNavigate } from "react-router-dom";
import { t, useTranslation } from "react-i18next";
import AccessibleButton from "../Accessibility/accessibility";
import { useToggle, useAppearance, useTour } from "../../context/index.js";
import { logEvent } from "../../analytics.js";
import { useEffect } from "react";
const SettingHelping = () => {
  const navigate = useNavigate();
  const { setCollapsibe } = useToggle();
  const { walkThrough, setWalkThrough } = useAppearance();
  const { t, i18n } = useTranslation();
  const { stopTour } = useTour();

  const openFeedbackWindow = () => {
    logEvent("happiness_meter_click", {
      language: i18n.language,
    });
    const width = window.screen.width * 0.9;
    const height = window.screen.height * 0.9;
    window.open(
      `https://geodubai.dm.gov.ae/${
        i18n.language === "ar" ? "ar" : "en"
      }/Pages/ContactUsForm.aspx`,
      "_blank",
      `width=${width},height=${height}`
    );
  };

  return (
    <div
      className="collapsibleMenuItems"
      style={{
        padding: "10px 0 25px 0",
      }}
    >
      <div
        className="collapsibleMenuItemWrapper MenuItemRow  Walkthrough_desc"
        style={{
          cursor: "pointer",
        }}
      >
        <AccessibleButton ariaLabel={t("Click here for walkthrough")}>
          <p>{t("Walkthrough")}</p>
        </AccessibleButton>
        <Toggle setChecked={setWalkThrough} checked={walkThrough} />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemRow "
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setCollapsibe(4);
          navigate("/legend", {
            state: {
              parent: "/settings",
            },
          });
        }}
      >
        <AccessibleButton ariaLabel={t("Click here for map legend")}>
          <p>{t("MapLegend")}</p>
        </AccessibleButton>
        <img src={rightArrow} alt="right arrow" />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemRow"
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setCollapsibe(4);
          navigate("/faq", {
            state: {
              parent: "/settings",
            },
          });
        }}
      >
        <AccessibleButton ariaLabel={t("Click here for FAQ")}>
          <p>{t("faq")}</p>
        </AccessibleButton>
        <img src={rightArrow} alt="right arrow" />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemRow "
        style={{
          cursor: "pointer",
        }}
        onClick={openFeedbackWindow}
      >
        <AccessibleButton ariaLabel={t("Click here to share feedback")}>
          <p>{t("shareFeedback")}</p>
        </AccessibleButton>
        <img src={rightArrow} alt="right arrow" />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemRow "
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setCollapsibe(4);
          navigate("/contact", {
            state: {
              parent: "/settings",
            },
          });
        }}
      >
        <AccessibleButton ariaLabel={t("Click here to contact support")}>
          <p>{t("contactSupport")}</p>
        </AccessibleButton>
        <img src={rightArrow} alt="right arrow" />
      </div>
      <div
        className="collapsibleMenuItemWrapper MenuItemRow "
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          setCollapsibe(4);
          navigate("/about", {
            state: {
              parent: "/settings",
            },
          });
        }}
      >
        <AccessibleButton ariaLabel={t("Click here for information")}>
          <p>{t("info")}</p>
        </AccessibleButton>
        <img src={rightArrow} alt="right arrow" />
      </div>
    </div>
  );
};
export default SettingHelping;
