import {
  searchByAddressArrow,
  buildingSearchIconGray,
} from "../../../assets/index.js";
import "./style.css";
import { useAppearance } from "../../../context/AppearanceContext.js";
import { useEffect, useState } from "react";
import { SearchIcon } from "../../../assets/svg/svgIcons.jsx";
import { useTranslation } from "react-i18next";
const CustomSelect = ({
  array,
  handleSelectOption,
  SelectedValue,
  placeholder,
  isExpanded,
  toggleDropdown,
  isActive,
  value,
  loading,
  allowLikeSearch,
  isMobileScreen,
}) => {
  const { theme } = useAppearance();
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [t, il8n] = useTranslation();
  useEffect(() => {
    if (!keyword) {
      setList(array);
      return;
    }

    const data = (array || []).filter((item) =>
      (item[value] || "").toLowerCase().includes(keyword.toLowerCase())
    );

    setList(data);
  }, [array, keyword, value]);
  return (
    <div
      className={`custom-select   ${isExpanded ? "active" : ""} ${
        isMobileScreen ? "is-mobile-screen" : ""
      }`}
    >
      <button
        className="select-button"
        role="combobox"
        aria-labelledby="select button"
        aria-haspopup="listbox"
        aria-expanded={isExpanded}
        onClick={toggleDropdown}
      >
        <span
          className="selected-value"
          style={{
            color: isActive
              ? theme === "dark"
                ? "#CFD5F2"
                : "#4E5972"
              : "#c6c6c6",
          }}
        >
          {SelectedValue || placeholder}
        </span>

        {isActive ? (
          <img
            src={searchByAddressArrow}
            alt="arrow"
            style={{
              transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        ) : (
          <img
            src={
              theme === "dark" ? searchByAddressArrow : buildingSearchIconGray
            }
            alt="arrow"
          />
        )}
      </button>

      {isExpanded && (
        <>
          {loading ? (
            <div
              style={{
                height: "14vh",
              }}
              className="select-dropdown"
            >
              {t("loading")}
            </div>
          ) : (
            <div className="serach-cover">
              <ul className="select-dropdown" role="listbox">
                {allowLikeSearch ? (
                  <div className="custom-select-otr">
                    <span className="search-icon">
                      <SearchIcon />
                    </span>
                    <input
                      type="text"
                      className="custom-select-search"
                      placeholder={t("search")}
                      onChange={(e) => {
                        setKeyword(e.target.value);
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}

                {list.map((item) => {
                  return (
                    <li
                      role="option"
                      onClick={() => {
                        handleSelectOption(item[value]);
                      }}
                    >
                      {item[value]}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomSelect;
