import { useTranslation } from "react-i18next";
import {
  PageHeaderTitle,
  CollapsibleMenu,
  MapAndNavigationSettings,
  AppearanceSettings,
  SettingDetermination,
  SettingHelping,
} from "../../component/index";
import { settingResponsiveIcon, atm } from "../../assets/index";
import { useEffect, useRef, useState } from "react";
import "../../scss/common.scss";
import { UseRemoveMapShape } from "../../customHooks";
import { useDraggableContext } from "../../context/DraggableContext";
import AccessibleButton from "../../component/Accessibility/accessibility";
import withTour from "../../withTour";
import React from "react";
import { useMapControl } from "../../context";

const Settings = () => {
  const [t] = useTranslation();
  const { distanceDragged } = useDraggableContext();
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const settingsContentRef = useRef(null);
  const { settingsAlert } = useMapControl();
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  UseRemoveMapShape();

  const setScrollProperties = () => {
    setTimeout(() => {
      if (
        isMobileScreen &&
        settingsContentRef &&
        settingsContentRef.current &&
        settingsContentRef.current.style
      ) {
        settingsContentRef.current.style.setProperty(
          "height",
          `${sideBarContentHeight}px`
        );
        settingsContentRef.current.style.setProperty("overflow-y", "auto");
      }
    }, 100);
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() => {
    const calculatedHeight = 95 - distanceDragged;
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  useEffect(() => {
    setScrollProperties();
  }, [isMobileScreen, settingsContentRef, sideBarContentHeight]);

  useEffect(() => {
    setScrollProperties();
  }, []);

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;
  return (
    <div>
      <div
        className="sideBarContent swipe-line settingSidebar"
        style={{
          padding: "",
          // overflowX: "auto",
          height:
            hightOfSidebar <= 350
              ? screenHeight - 125
              : hightOfSidebar | (screenHeight - 191),
        }}
      >
        <PageHeaderTitle
          title={t("settings")}
          responsiveIcon={settingResponsiveIcon}
        />

        <div className="settingsContent" ref={settingsContentRef}>
          <CollapsibleMenu
            items={[
              {
                img: atm,
                title: (
                  <AccessibleButton
                    ariaLabel={t("Click here to change language")}
                  >
                    {t("language")}
                  </AccessibleButton>
                ),
              },
              {
                img: atm,
                title: (
                  <AccessibleButton
                    ariaLabel={t("Click here for map settings")}
                  >
                    {t("mapsetting")}
                  </AccessibleButton>
                ),
              },
              {
                img: atm,
                title: (
                  <AccessibleButton
                    ariaLabel={t("Click here to change appearance")}
                  >
                    {t("appearance")}
                  </AccessibleButton>
                ),
              },
              {
                img: atm,
                title: (
                  <AccessibleButton
                    ariaLabel={t(
                      "Click here for people of determination settings"
                    )}
                  >
                    {t("peopleOfDetermination")}
                  </AccessibleButton>
                ),
              },
              {
                img: atm,
                title: (
                  <AccessibleButton ariaLabel={t("Click here for help")}>
                    {t("help")}
                  </AccessibleButton>
                ),
              },
            ]}
            defaultActiveIndex={3}
            notHasArrow={true}
            hasLogo={false}
            children={[
              <></>,
              <MapAndNavigationSettings />,
              <AppearanceSettings />,
              <SettingDetermination />,
              <SettingHelping />,
            ]}
            className="itemTitleSetting"
            itemStyle={{
              padding: "19px 0",
            }}
          />
        </div>

        <div
          class="copy_right"
          style={{
            paddingTop: "1.3rem",
          }}
        >
          {t("Copyright")}
        </div>
      </div>
      <div
        style={{
          height: window?.innerWidth < 763 ? "100%" : "0",
          zIndex: 99999,
        }}
      >
        {settingsAlert.value && settingsAlert.message && (
          <div className="settingsAlert ">
            <div className="body-content">
              <p className="allowAccessDesc">{settingsAlert.message}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTour(Settings, "settings");
