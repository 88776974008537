import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageHeaderTitle, Upload } from "../../component";
import { useNavigate } from "react-router-dom";
import jsQR from "jsqr";
import EXIF from "exif-js";

import { UseRemoveMapShape } from "../../customHooks";
import { useDraggableContext } from "../../context/DraggableContext";
import AccessibleButton from "../../component/Accessibility/accessibility";
import React from "react";

const UploadQR = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { distanceDragged } = useDraggableContext();
  const [sideBarContentHeight, setSideBarContentHeight] = useState(350);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [file, setFile] = useState();
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [geolocation, setGeolocation] = useState(null);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  UseRemoveMapShape();

  function getQrData(data) {
    const urlPattern = /^https?:\/\/[^\s]+/;

    if (urlPattern.test(data)) {
      const url = new URL(data);
      const urlParams = new URLSearchParams(url.search);
      const qrData = urlParams.get("m");

      if (qrData === null) {
        setError("INVALID_QR");
        return null;
      }

      return qrData;
    } else {
      return data;
    }
  }

  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };

  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      setError(null);
      setFile(file.name);
      try {
        const imageDataUrl = await readFile(file);
        processImage(imageDataUrl);
      } catch (error) {
        setError("Error reading file.");
        setResult(null);
        setLoading(false);
      }
    }
  };

  const processImage = (imageDataUrl) => {
    const image = new Image();
    image.src = imageDataUrl;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        const parsedData = getQrData(code.data);
        setResult(parsedData);
        setLoading(false);
      } else {
        extractGeolocation(image);
        setResult(null);
        setError(null);
      }
    };
    image.onerror = () => {
      setError("Error loading image.");
      setResult(null);
      setLoading(false);
    };
  };

  useEffect(() => {
    setIsMobileScreen(window.innerWidth <= 767);
  }, []);

  useEffect(() => {
    const calculatedHeight = 140 - distanceDragged; // adjust 140 according to the height of the header
    setSideBarContentHeight(calculatedHeight);
  }, [distanceDragged]);

  const convertDMSToDD = (DMSArray, ref) => {
    const degrees = DMSArray[0] + DMSArray[1] / 60 + DMSArray[2] / 3600;
    let dd = ref === "S" || ref === "W" ? -1 * degrees : degrees;
    return dd;
  };

  const extractGeolocation = (image) => {
    EXIF.getData(image, function () {
      const latitude = EXIF.getTag(this, "GPSLatitude");
      const latitudeRef = EXIF.getTag(this, "GPSLatitudeRef");
      const longitude = EXIF.getTag(this, "GPSLongitude");
      const longitudeRef = EXIF.getTag(this, "GPSLongitudeRef");
      if (latitude && latitudeRef && longitude && longitudeRef) {
        const lat = convertDMSToDD(latitude, latitudeRef);
        const lng = convertDMSToDD(longitude, longitudeRef);

        setGeolocation({ lat, lng });
        setLoading(false);
      } else {
        setGeolocation(null);
        setLoading(false);
      }
    });
  };

  React.useEffect(() => {
    const updatescreenHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updatescreenHeight);

    window.addEventListener("orientationchange", updatescreenHeight);

    return () => {
      window.removeEventListener("resize", updatescreenHeight);
      window.removeEventListener("orientationchange", updatescreenHeight);
    };
  }, []);

  let hightOfSidebar = screenHeight - 191;

  return (
    <div
      className="sideBarContent swipe-line"
      style={{
        padding: "",
        height:
          hightOfSidebar <= 350
            ? screenHeight - 125
            : hightOfSidebar | (screenHeight - 191),
      }}
    >
      <PageHeaderTitle title={t("uploadQR")} />
      <div
        className="searchContent"
        style={{
          overflowY: "auto",
          height: isMobileScreen ? `${sideBarContentHeight}px` : "auto", // added scroll ability for mobile screen
        }}
      >
        <AccessibleButton ariaLabel={t("upload your Qr code here")}>
          <div className="searchByLandWarper">
            <Upload fileName={file} handleChange={handleChange} />
          </div>
        </AccessibleButton>

        {result && (
          <p
            style={{
              margin: "10px 0",
            }}
          >
            {t("scannedQRCodeValue")} : {result}
          </p>
        )}
        {geolocation && (
          <div
            style={{
              padding: "10px 0",
            }}
          >
            <p>{t("upload")}</p>
          </div>
        )}
        {loading && <p>{t("loading")}</p>}
        {!loading &&
          file &&
          !geolocation &&
          !result &&
          error !== "INVALID_QR" && (
            <div
              style={{
                padding: "10px 0",
              }}
            >
              <p>No result Found</p>
            </div>
          )}
        {error && error === "INVALID_QR" ? (
          <p
            style={{
              margin: "10px 0",
              color: "red",
            }}
          >
            {t("Invalid QR Code")}
          </p>
        ) : (
          error && <p className="error">{error}</p>
        )}
        <div className="searchByLandBtnWarper">
          <div
            className={
              file ? "searchByBuildingBtnActive" : "searchByBuildingBtn"
            }
            onClick={() => {
              if (geolocation) {
                navigate("/searchResult", {
                  state: {
                    lat: geolocation.lat,
                    lng: geolocation.lng,
                    from: "/uploadQR",
                  },
                });
              }
              if (result) {
                navigate("/searchResult", {
                  state: {
                    dgis: result.replace(/ /g, ""),
                    featureclassId: 2,
                    from: "/uploadQR",
                  },
                });
              }
            }}
          >
            {t("search")}
          </div>
        </div>
      </div>
      <div class="copy_right">{t("Copyright")}</div>
    </div>
  );
};
export default UploadQR;
