import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useAppearance,
  useFavorites,
  useMapControl,
} from "../../../context/index.js";

import {
  flagSmall,
  flagBig,
  flagBigDark,
  flagSmallDark,
} from "../../../assets/index";
import { parseDistanceWithUnit } from "../../../helper/utils/parseDistance.js";
const EditFavoritesRoutes = ({
  item,
  setEditRoute,
  value,
  setValue,
  isLocation,
}) => {
  const inputRef = useRef(null);
  const [t, il8n] = useTranslation();
  const { theme } = useAppearance();
  const { fetchSyncFavorites } = useFavorites();
  const { measurementUnit } = useMapControl();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const handelSave = () => {
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    fetchSyncFavorites(
      {
        FavType: item.values[0].FavType,
        FavName: item.name,
      },
      {
        action: "edit",
        value: value,
        lastUpdate: timestamp,
        FromRoute: true,
      }
    );
    setEditRoute({
      isEditLocationToggle: false,
      index: -1,
    });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handelSave();
    }
  };

  return (
    <div
      className="editFavoriteLocation"
      style={{
        margin: "0",
      }}
    >
      <div className="EditableFavouritePlace">
        <div className="favouritePlaceLeft">
          <div className="routesLogoWarper">
            <img
              src={theme === "dark" ? flagBigDark : flagBig}
              alt="routes logo"
              className="flag"
            />
            <img
              src={theme === "dark" ? flagSmallDark : flagSmall}
              alt="routes logo"
              className="flag2"
            />
          </div>
          <div className="favouritePlaceDetails">
            <p className="favouritePlaceTitle">{item.name}</p>
            <p className="favouritePlaceSubTitle">
              {" "}
              {il8n.language === "ar" ? item.BuildingNameA : item.BuildingNameE}
            </p>
          </div>
        </div>
        <div className="favouritePlaceRight">
          <div>
            {isLocation && (
              <p
                className="favouritePlaceMakainNumber"
                style={{
                  textAlign: il8n.language === "ar" ? "left" : "right",
                }}
              >
                <span>
                  {!isLocation
                    ? "--"
                    : parseDistanceWithUnit(
                        measurementUnit,
                        Math.round((item.values[0].FavValue / 1000) * 10) / 10
                      )}{" "}
                </span>{" "}
                {measurementUnit == "kilometers" ? t("km") : t("miles")}
              </p>
            )}
            {/* <p
                className="favouritePlaceMakainDistance"
                style={{
                  textAlign: il8n.language === "ar" ? "left" : "right",
                }}
              >
                {item.values[0].FavValue}
              </p> */}
          </div>
        </div>
      </div>
      <div className="EditFavoriteLocation">
        <div className="EditFavoriteLocationInputWraper">
          <input
            ref={inputRef}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
        <div className="EditFavoriteBtns">
          <button
            className="CancelFavoriteLocationBtn"
            onClick={() => {
              setEditRoute({
                isEditLocationToggle: false,
                index: -1,
              });
            }}
          >
            {t("cancel")}
          </button>
          <button
            className="EditFavoriteLocationBtn"
            onClick={() => {
              handelSave();
            }}
          >
            {" "}
            {t("save")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFavoritesRoutes;
