import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../../context/LanguageContext";
import { arrow, arrowUp } from "../../assets/index";
import { useLocation } from "react-router-dom";
import SettingToggle from "../common/settingToggle";
import POICards from "../POICards/index";
import { useGetAllPOIsOfCategoriesFetching } from "../../customHooks/index";
import { useToggle, useAppearance } from "../../context/index.js";

const CollapsibleMenu = ({
  children,
  title,
  items,
  hasLogo,
  itemTitleStyle,
  itemStyle,
  notHasArrow,
  defaultActiveIndex = null,
}) => {
  const [activeItems, setActiveItems] = useState(
    defaultActiveIndex !== null ? [defaultActiveIndex] : []
  );
  const [POIs, setPOIs] = useState({});
  const [t, il8n] = useTranslation();
  const { handleLangChange } = useLanguage();
  const { GetAllPOIsOfCategories } = useGetAllPOIsOfCategoriesFetching(
    setPOIs,
    POIs
  );
  const location = useLocation();
  const { setCollapsibe, collapsible } = useToggle();
  const { walkThrough } = useAppearance();

  useEffect(() => {
    if (walkThrough) {
      setActiveItems([4, 3]);
    }
  }, [walkThrough]);

  useEffect(() => {
    if (collapsible === 4 && location.pathname === "/settings") {
      setActiveItems([4]);
    }
  }, [collapsible]);
  // const toggleCollapsible = (index, key) => {

  //   GetAllPOIsOfCategories(key);
  //   if (activeItems.includes(index)) {
  //     setActiveItems(activeItems.filter((item) => item !== index));

  //   } else {
  //     setActiveItems([...activeItems, index]);
  //   }
  // };
  const toggleCollapsible = (index, key) => {
    GetAllPOIsOfCategories(key);
    if (activeItems.includes(index)) {
      setActiveItems([]);
    } else {
      setActiveItems([index]);
    }
  };

  const change = (lang) => {
    const direction = lang === "ar" ? "rtl" : "ltr";
    handleLangChange(direction, lang);
  };

  const toggleLanguage = (lang) => {
    change(lang);
  };

  return (
    <div>
      {title && (
        <div className="POIMenuTitle">
          <p>{title}</p>
        </div>
      )}
      {items.map((item, index) => {
        return (
          <div key={item.key || index}>
            <div
              className={`collapsible ${
                activeItems.includes(index) ? "active" : ""
              }`}
              onClick={() => toggleCollapsible(index, item.key)}
            >
              <div className="POIMenuItem" style={{ ...itemStyle }}>
                {hasLogo && (
                  <div className="POIMenuItemLogo">
                    <img src={item.img} alt="atm" />
                  </div>
                )}

                <div
                  className="POIMenuItemTitle"
                  style={{
                    ...itemTitleStyle,
                  }}
                >
                  <p>{item.title}</p>
                </div>
                <div
                  className="arrowMenuWarper"
                  style={{
                    marginRight: il8n.language === "ar" && "auto",
                    marginLeft: il8n.language === "en" && "auto",
                  }}
                >
                  {notHasArrow && index === 0 ? (
                    <></>
                  ) : (
                    <>
                      {activeItems.includes(index) ? (
                        <img src={arrowUp} alt="arrowUp" />
                      ) : (
                        <img src={arrow} alt="arrow" />
                      )}
                    </>
                  )}
                  {notHasArrow && index === 0 && (
                    <SettingToggle
                      values={{
                        ar: "العربية",
                        en: "English",
                      }}
                      value={il8n.language}
                      setState={toggleLanguage}
                      lang={true}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              className={`content ${
                activeItems.includes(index) ? "active" : ""
              } `}
            >
              {(children?.length > 0 && children[index]) || (
                <POICards pois={POIs[item.key]} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CollapsibleMenu;
