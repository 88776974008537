import React from "react";
import { useTranslation } from "react-i18next";
// import "./scss/common.scss";
import "./scss/common.scss";

const TourSteps = () => {
  const [t, il8n] = useTranslation();
  const tourSteps = {
    app: [
      {
        title: <div className="joyride-title">{t("search")}</div>,
        target: ".search_des",
        content: <div className="joyride-content">{t("search-journey")}</div>,

        // placement: "bottom",
      },
      {
        target: ".SignIn",
        title: <div className="joyride-title">{t("signIn")}</div>,
        content: <div className="joyride-content">{t("signIn-journey")}</div>,
        // disableScrolling: true,
        // placement: "bottom",
      },
      {
        title: <div className="joyride-title">{t("findMyLandNumber")}</div>,
        target: ".Enter_land_number_desc",
        content: (
          <div className="joyride-content">{t("findMyLandNumber-journey")}</div>
        ),
        // disableScrolling: true,
      },
      {
        title: <div className="joyride-title">{t("findMyBuilding")}</div>,
        target: ".search_by_community_desc",
        content: (
          <div className="joyride-content">{t("findMyBuilding-journey")}</div>
        ),
        // offset: -4,
        // placement: "right",
        // placement: "top",
        // disableScrolling: true,
      },
      {
        target: ".scan_qr_desc",
        content: (
          <div className="joyride-content">{t("scanQRCode-journey")}</div>
        ),
        title: <div className="joyride-title">{t("scanQRcode")}</div>,
        // offset: -4,
        // placement: "top",
        // disableScrolling: true,
      },
      {
        target: ".main_menu_desc",
        title: t("mainMenu"),
        content: (
          <div dangerouslySetInnerHTML={{ __html: t("mainMenu-journey") }} />
        ),
        // offset: -4,
      },
      {
        target: ".search_poi_desc",
        title: (
          <div className="joyride-title">{t("searchByPointOfInterest")}</div>
        ),
        content: (
          <div className="joyride-content">{t("searchByPOI-journey")}</div>
        ),

        // offset: -4,
        // disableScrolling: true,
        // placement: "top",
        // scrollOffset: 1000,
      },
      {
        title: <div className="joyride-title">{t("qiblaDirection")}</div>,
        target: ".qibla_direction",
        content: <div className="joyride-content">{t("qibla-journey")}</div>,
        // offset: -1,
      },
      {
        title: <div className="joyride-title">{t("zoomIn")}</div>,
        target: ".zoom_in_desc",
        content: <div className="joyride-content">{t("zoomIn-journey")}</div>,
        // offset: -1,
      },
      {
        title: <div className="joyride-title">{t("zoomOut")}</div>,
        target: ".zoom_out_desc",
        content: <div className="joyride-content">{t("zoomOut-journey")}</div>,
        // offset: -1,
        placement: "left",
      },
      {
        title: <div className="joyride-title">{t("mapLayer")}</div>,
        target: ".Map_layer_desc",
        content: (
          <div
            dangerouslySetInnerHTML={{ __html: t("mapLayer-journey") }}
            style={{ textAlign: "left" }}
            className="joyride-content"
          />
        ),
        // offset: -1,
      },
      {
        title: <div className="joyride-title">{t("currentLocation")}</div>,
        target: ".Current_location_desc",
        content: (
          <div className="joyride-content">{t("currentLocation-journey")}</div>
        ),
        // offset: -1,
      },
      {
        target: ".start_nav",
        title: <div className="joyride-title">{t("navigation")}</div>,
        content: (
          <div className="joyride-content">{t("navigation-journey")}</div>
        ),
        // offset: -1,
      },
      {
        target: ".map_tap",
        title: <div className="joyride-title">{t("mapTap")}</div>,
        content: <div className="joyride-content">{t("mapTap-journey")}</div>,
        // offset: -1,
      },
      {
        target: ".long-pres-desc",
        title: <div className="joyride-title">{t("mapLongPress")}</div>,
        content: (
          <div className="joyride-content">{t("mapLongPress-journey")}</div>
        ),
        // offset: -1,
      },
      {
        target: ".setting_desc",
        title: <div className="joyride-title">{t("settings")}</div>,
        content: (
          <div
            dangerouslySetInnerHTML={{ __html: t("settings-journey") }}
            style={{ textAlign: "left" }}
            className="joyride-content"
          />
        ),
        // offset: -4,
      },
      {
        target: ".about_desc",
        title: <div className="joyride-title">{t("aboutUs")}</div>,
        content: <div className="joyride-content">{t("aboutUs-journey")}</div>,
        // offset: -4,
      },
      {
        target: ".report_damage",
        title: <div className="joyride-title">{t("reportDamage")}</div>,
        content: (
          <div className="joyride-content">{t("reportDamage-journey")}</div>
        ),
        // offset: -4,
      },
    ],
    searchResultPage: [
      {
        target: ".add_makani_desc",
        title: <div className="joyride-title">{t("addMakani")}</div>,
        content: (
          <div className="joyride-content">{t("addMakani-journey")}</div>
        ),
        offset: -4,
      },

      {
        target: ".street-view-desc",
        title: <div className="joyride-title">{t("streetView")}</div>,
        content: (
          <div className="joyride-content">{t("streetView-journey")}</div>
        ),
        offset: -4,
      },
      {
        target: ".share_location_desc",
        title: <div className="joyride-title">{t("shareLocation")}</div>,
        content: (
          <div className="joyride-content">{t("shareLocation-journey")}</div>
        ),
        offset: -4,
      },

      {
        target: ".direction_desc",
        title: <div className="joyride-title">{t("calculateRoute")}</div>,
        content: (
          <div className="joyride-content">{t("calculateRoute-journey")}</div>
        ),
        offset: -4,
      },
      {
        target: ".add_favorite_desc",
        title: <div className="joyride-title">{t("addFavoritePlace")}</div>,
        content: (
          <div className="joyride-content">{t("addFavoritePlace-journey")}</div>
        ),
        offset: -4,
      },
      {
        target: ".indoor-navigation_desc",
        title: <div className="joyride-title">{t("indoorNavigation")}</div>,
        content: (
          <div className="joyride-content">
            {t("indoorNavigationDescription")}
          </div>
        ),
        offset: -4,
      },
    ],
    // searchResult:[

    // ],
    direction: [
      {
        target: ".start_point",
        content: <div className="joyride-content">{t("startingPoint")}</div>,
        offset: -4,
      },
      {
        target: ".Add_destination",
        content: (
          <div className="joyride-content">{t("addDestination-journey")}</div>
        ),
        offset: -4,
      },
      {
        target: ".Swipe_destination_desc",
        content: (
          <div className="joyride-content">
            {t("switchDestinations-journey")}
          </div>
        ),
        title: <div className="joyride-title">{t("switchDestination")}</div>,
        offset: -4,
      },
      {
        target: ".Add_favourite_desc",
        title: <div className="joyride-title">{t("addRouteToFavourite")}</div>,
        content: (
          <div className="joyride-content">
            {t("addRouteToFavourite-journey")}
          </div>
        ),
        offset: -4,
      },
      {
        target: ".Start_navigation",
        content: (
          <div className="joyride-content">{t("startNavigation-journey")}</div>
        ),
        offset: -4,
      },
    ],

    about: [
      {
        target: ".step1",
        content: "This is the first step of the tour on the About page.",
        title: "First Step",
        offset: -4,
      },
      {
        target: ".step2",
        content: "This is the second step of the tour on the About page.",
        title: "Second Step",
        offset: -4,
      },
    ],
    indoorNavigation: [
      {
        target: ".start_point_indoor",
        title: <div className="joyride-title">{t("startingPoint")}</div>,
        content: <div className="joyride-content">{t("addStartPoint")}</div>,
        offset: -4,
      },
      {
        target: ".end_point_indoor",
        title: <div className="joyride-title">{t("endPoint")}</div>,
        content: <div className="joyride-content">{t("addEndPoint")}</div>,
        offset: -4,
      },

      {
        target: ".travel_mode_indoor",
        title: <div className="joyride-title">{t("Travel Mode")}</div>,
        content: (
          <div className="joyride-content">{t("travel-points-content")}</div>
        ),
        offset: -4,
      },
      {
        target: ".swap_item",
        title: <div className="joyride-title">{t("swap-points")}</div>,
        content: (
          <div className="joyride-content">{t("swap-points-content")}</div>
        ),
        offset: -4,
      },
    ],
    settings: [
      {
        target: "s",
        title: "",
        content: " ",
        // offset: -4,
        // placement: "right",
      },
      {
        target: ".Talkback_desc",
        title: <div className="joyride-title">{t("talkback")}</div>,
        content: <div className="joyride-content">{t("talkback-journey")}</div>,
        placement: "right",
        styles: {
          options: {
            // width: 300,
            position: "fixed",
          },
        },
      },
      {
        target: ".GrayScale_desc",
        title: <div className="joyride-title">{t("greyscale")}</div>,
        content: (
          <div className="joyride-content">{t("greyscale-journey")}</div>
        ),
        // offset: -4,
      },
      {
        target: ".Font_size_desc",
        content: <div className="joyride-title">{t("fontSize-journey")}</div>,
        title: <div className="joyride-content">{t("fontsize")}</div>,
        // offset: -4,
      },
      {
        target: ".Walkthrough_desc",
        content: <div className="joyride-title">{t("walkthroug-journey")}</div>,
        title: <div className="joyride-content">{t("Walkthrough")}</div>,
        // offset: -4,
      },
    ],
  };

  return tourSteps;
};
export default TourSteps;
///18:55
